import * as React from "react";
import { Box, Text, Heading } from "rebass/styled-components";

const Why = React.memo(() => {
  return (
    <Box
      sx={{
        maxWidth: 1024,
        mx: "auto",
        px: 3,
        py: "3em",
      }}
    >
      <Heading fontSize={[3, 4, 5]} my="0.3em">
        Cos'è Balanu?
      </Heading>
      <Text fontSize={[1]} mt="1.4em" marginBottom="1em" color="text">
        Balanu è una piattaforma che connette persone e ricette culinarie.
      </Text>

      <Text fontSize={[1]} marginBottom="1em" color="text">
        Oltre ad aiutarti a mangiare in modo sano ed equilibrato — scegliendo
        tra diversi regimi alimentari — può farti risparmiare sulla spesa ed
        evitare sprechi di cibo tramite la lista della spesa intelligente, la
        gestione degli ingredienti e la ricerca di ricette basata sugli
        ingredienti che sono già nel tuo frigo.
      </Text>

      <Text fontSize={[1]} marginBottom="1em" color="text">
        Scopri il piacere di cucinare, impara nuove ricette, perfeziona le tue
        capacità culinarie, ma soprattutto divertiti.
      </Text>
    </Box>
  );
});

export default Why;
