import * as React from "react";
import { Box, Heading, Text } from "rebass/styled-components";

import styled from "styled-components";
import LeadForm from "../lead-form";

const Wrapper = styled.div`
  padding-top: 5em;
  background: rgb(233, 233, 233, 1);
  background: radial-gradient(
      circle,
      rgba(124, 195, 117, 0.5) 0%,
      rgba(233, 233, 233, 1) 50%
    )
    rgb(233, 233, 233, 1);
  background-position: 200px;
  background-repeat: no-repeat;
  height: 30em;
`;

const Header = React.memo(() => {
  return (
    <>
      <Wrapper>
        <Box
          sx={{
            maxWidth: 1024,
            mx: "auto",
            px: 3,
          }}
        >
          <Text
            style={{ textTransform: "uppercase" }}
            fontSize={[1]}
            marginBottom="1.2em"
            fontWeight="700"
            color="text"
          >
            Ciao, prossimo utente
          </Text>
          <Heading fontSize={[5, 6, 7]}>
            Inizia a mangiare come si deve.
          </Heading>

          <LeadForm />
        </Box>
      </Wrapper>
    </>
  );
});

export default Header;
