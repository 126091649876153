import * as React from "react";
import { Box, Text, Heading } from "rebass/styled-components";
import styled from "styled-components";

import SearchSvg from "../../assets/svgs/search.svg";
import RobotSvg from "../../assets/images/robot.png";
import ListSvg from "../../assets/images/shopping-list.png";
import WishlistSvg from "../../assets/images/wishlist.png";

const BoxesWrapper = styled.div`
  & .what-box {
    padding-right: 2em;
  }

  & .what-box:nth-child(odd) {
    border-right: 2px solid rgba(233, 233, 233, 1);
  }
`;

const BoxImageWrapper = styled.div`
  background: rgb(233, 233, 233, 1);
  background: radial-gradient(
      circle,
      rgba(124, 195, 117, 0.5) 30%,
      rgba(233, 233, 233, 1) 70%
    )
    rgb(233, 233, 233, 1);
  background-position: 50px 50px;
  background-repeat: no-repeat;
  border-radius: 100%;
  padding: 3em;
  width: 4em;
`;

const BoxWithIcon = ({ image, title, description }) => {
  return (
    <Box className="what-box" my="2em" textAlign="left">
      <BoxImageWrapper>
        <img
          style={{ height: "100%", width: "100%" }}
          src={image}
          alt="Icona feature"
        />
      </BoxImageWrapper>
      <Heading py={3}>{title}</Heading>
      <Text fontSize={[1]} color="text">
        {description}
      </Text>
    </Box>
  );
};

const What = React.memo(() => {
  return (
    <Box
      sx={{
        maxWidth: 1024,
        mx: "auto",
        px: 3,
        py: "3em",
      }}
    >
      <Heading fontSize={[3, 4, 5]} my="0.3em">
        Scopri come Balanu può aiutarti
      </Heading>

      <BoxesWrapper>
        <Box
          sx={{
            display: "grid",
            gridGap: 4,
            gridTemplateColumns: "repeat(auto-fit, minmax(45%, 1fr))",
          }}
        >
          <BoxWithIcon
            image={SearchSvg}
            title="Scopri ricette fenomenali"
            description="Abbiamo da proporti una cosa come 40.000 ricette. Siamo sicuri che impostanto le tue preferenze e utilizzando i filti, riuscirai a trovare quelle perfette per te."
          />
          <BoxWithIcon
            image={RobotSvg}
            title="Generatore automatico"
            description="Balanu genera per te un calendario alimentare, influenzato dalle tue preferenze e dai piatti che hai già preparato in passato. Comodo no?"
          />

          <BoxWithIcon
            image={ListSvg}
            title="Lista della spesa intelligente"
            description={`Ti permetterà di fare una spesa intelligente, divisa per categorie e per giorni. Acquista solo il necessario per le tue ricette, evita gli sprechi`}
          />
          <BoxWithIcon
            image={WishlistSvg}
            title="Liste per ogni occasione"
            description="Natale? Pasqua? Compleanno? Crea liste di ricette per ogni occasione"
          />
        </Box>
      </BoxesWrapper>
    </Box>
  );
});

export default What;
