import { Input as RebassInput } from "@rebass/forms/styled-components";
import React from "react";

const Input = React.memo(({ id, name, type, placeholder, ...others }) => {
  return (
    <RebassInput
      {...others}
      sx={{
        m: ".8em 0",
        p: "1em",
        boxShadow: "material",
        border: "none",
        backgroundColor: "white",
      }}
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
    />
  );
});

export default Input;
