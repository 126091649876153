import * as React from "react";
import Header from "../components/header";
import Layout from "../components/layout";
import What from "../components/what";
import Why from "../components/why";
import Mockups from "../components/mockups";
import Get from "../components/get";

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <Mockups />
      <Why />
      <What />
      <Get />
    </Layout>
  );
};

export default IndexPage;
