import * as React from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import LeadForm from "../lead-form";

const GetWrapper = styled.div`
  background-color: rgb(233, 233, 233, 1);
`;

const Get = React.memo(() => {
  return (
    <GetWrapper>
      <Box
        sx={{
          maxWidth: 1024,
          mx: "auto",
          px: 3,
          py: "3em",
        }}
      >
        <LeadForm />
      </Box>
    </GetWrapper>
  );
});

export default Get;
