import * as React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const ImagesWrapper = styled.div`
  margin-top: 4em;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > img {
    height: 40em;
  }

  & > img:nth-child(1) {
  }
`;

const Mockups = React.memo(() => {
  return (
    <Box
      sx={{
        maxWidth: 1024,
        mx: "auto",
        px: 3,
        py: "3em",
      }}
    >
      <ImagesWrapper>
        <StaticImage
          placeholder="none"
          src="../../images/mocks.png"
          alt="balanu schermata d'esempio"
        />
      </ImagesWrapper>
    </Box>
  );
});

export default Mockups;
