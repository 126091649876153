import * as React from "react";
import { Formik } from "formik";
import { Box, Button, Text, Heading } from "rebass/styled-components";
import Modal from "react-modal";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import Input from "../input";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const LeadForm = React.memo(() => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Heading
        style={{ marginTop: "1.4em", marginBottom: ".2em" }}
        fontSize={[3]}
      >
        Ottieni Balanu gratis
      </Heading>

      <Text
        style={{ marginBottom: 0 }}
        fontSize={["1em"]}
        marginBottom="1.2em"
        color="text"
      >
        Rendi più salutare la tua vita, riduci gli sprechi di cibo, risparmia
        tempo e soldi
      </Text>
      <Text fontSize={[1]} marginBottom="1.2em" color="text">
        - ma prima, ti manderò un codice per confermare la tua email.
      </Text>

      <Formik
        initialValues={{ email: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "L'email è obbligatoria, ci dispiace";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "L'email inserita non è valida";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          openModal();
          trackCustomEvent({
            category: "Email input",
            action: "input",
            label: "Landing",
            value: values.email,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              my="2em"
              sx={{
                maxWidth: 300,
              }}
            >
              <Input
                name="email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="tua@mail.com"
              />
              <Text fontSize={[1]} marginBottom="1em" color="secondary">
                {errors.email && touched.email && errors.email}
              </Text>
            </Box>

            <Button
              type="submit"
              disabled={isSubmitting}
              variant="primary"
              mr={2}
            >
              Iniziamo
            </Button>
          </form>
        )}
      </Formik>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box
          my="2em"
          sx={{
            maxWidth: 400,
          }}
        >
          <Heading mb="3" fontSize={[3, 4, 5]}>
            Grazie!
          </Heading>
          <Text my="1">
            Purtroppo al momento Balanu non è ancora disponibile per tutti gli
            utenti.
          </Text>

          <Text my="1em">
            Presto apriremo le adesione al team di beta-testing, controlla la
            tua casella email nelle prossime settimane 😏
          </Text>

          <Text my="1">
            Se vuoi aiutarci a migliorare Balanu,{" "}
            <OutboundLink
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeyTzXXMHhuiwI4rbsNMGt4JbFlJFp9Ddx6wycXQqeKi-b50g/viewform"
            >
              compila il questionario
            </OutboundLink>
            .
          </Text>
        </Box>
      </Modal>
    </>
  );
});

export default LeadForm;
